import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { de } from "../locales/de";
import { en } from "../locales/en";

i18n
	// Enable automatic language detection
	.use(LanguageDetector)
	// Enables the hook initialization module
	.use(initReactI18next)
	.init({
		// Standard language used
		fallbackLng: "de",
		interpolation: {
			escapeValue: false,
		},
		resources: {
			de: { translation: de },
			en: { translation: en },
		},
	});

export default i18n;
