import React, { ReactElement, useState } from "react";
import { AutoComplete, message, Spin } from "antd";
import { Map } from "leaflet";
import { useTranslation } from "react-i18next";
import { OptionData, OptionGroupData } from "rc-select/lib/interface";

import locationService from "../../services/locationService";
import { StopLocationOrCoordLocation } from "../../utilities/location-api";
import { SearchResultLocation } from "../../models/SearchResultLocation";

import styles from "./index.module.scss";

type SearchResultOption = {
	label: string;
	value: string;
};

type SearchProps = {
	mapRef?: Map;
	onSearchCallback: (searchResultLocation: SearchResultLocation) => void;
};

const SEARCH_RESULT_ZOOM_LEVEL = 14;

const Search = ({ mapRef, onSearchCallback }: SearchProps): ReactElement => {
	const { t } = useTranslation();

	const [searchOptions, setSearchOptions] = useState<SearchResultOption[]>([]);
	const [isSearching, setIsSearching] = useState<boolean>(false);

	const handleSearch = async (searchString: string): Promise<void> => {
		if (searchString !== "") {
			setIsSearching(true);

			try {
				const searchResults = await locationService.getLocations(searchString);
				updateDropdown(searchResults);
			} catch (_) {
				message.error({
					content: t("search.not-possible"),
					duration: 10,
					key: "searchError",
				});
			} finally {
				setIsSearching(false);
			}
		}
	};

	const handleSelect = (_: string, option: OptionData | OptionGroupData) => {
		if (option.value && option.label) {
			const selected: SearchResultLocation = {
				name: option.label.toString(),
				geolocation: {
					type: "Point",
					coordinates: option.value.toString().split(","),
				},
			};

			onSearchCallback(selected);
			mapRef?.flyTo(
				[
					selected.geolocation.coordinates[0],
					selected.geolocation.coordinates[1],
				],
				SEARCH_RESULT_ZOOM_LEVEL
			);
		}
	};

	const updateDropdown = (
		searchResults: StopLocationOrCoordLocation[]
	): void => {
		const searchOptions = searchResults.reduce(
			(options: SearchResultOption[], location) => {
				if ("StopLocation" in location) {
					const { lat, lon, name } = location.StopLocation;

					options.push({
						label: name,
						value: [lat, lon].toString(),
					});
				}

				if ("CoordLocation" in location) {
					const { lat, lon, name } = location.CoordLocation;

					options.push({
						label: name,
						value: [lat, lon].toString(),
					});
				}

				return options;
			},
			[]
		);

		setSearchOptions(searchOptions);
	};

	const handleBlur = () => {
		setSearchOptions([]);
	};

	return (
		<div className={styles["search"]}>
			<AutoComplete
				options={searchOptions}
				style={{ width: "100%" }}
				allowClear
				placeholder={t("search.placeholder")}
				onSearch={handleSearch}
				onSelect={handleSelect}
				onBlur={handleBlur}
				autoFocus
				notFoundContent={isSearching ? <Spin size="small" /> : null}
			/>
		</div>
	);
};

export default Search;
