import React, { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Layout } from "antd";

import { defaults as locationApi } from "./utilities/location-api";

import Map from "./components/Map";
import Footer from "./components/Footer";

import "leaflet/dist/leaflet.css";
import "antd/dist/antd.css";

function App(): ReactElement {
	const queryClient = new QueryClient();

	locationApi.baseUrl = process.env.REACT_APP_SP_LOCATION_API_URL;

	return (
		<QueryClientProvider client={queryClient}>
			<Layout>
				<Layout.Content>
					<Map />
				</Layout.Content>

				<Layout.Footer style={{ padding: 0 }}>
					<Footer />
				</Layout.Footer>
			</Layout>

			<ReactQueryDevtools initialIsOpen={false} position="top-left" />
		</QueryClientProvider>
	);
}

export default App;
