/**
 * Location Search
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
	baseUrl: "https://api.bvg.de/fgi",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
	vgiProd: "https://api.bvg.de/fgi",
	vgiTest: "https://api-test.bvg.de/fgi",
};
export type FilterMode =
	| "DIST_ATTR"
	| "DIST_INFO"
	| "DIST_PERI"
	| "DIST_PERI_REQ"
	| "DIST_RNG"
	| "DIST_STNR"
	| "DIST_STNR_PREF"
	| "EXCL_ATTR"
	| "EXCL_INFO"
	| "EXCL_META"
	| "EXCL_PERI"
	| "EXCL_PERI_REQ"
	| "EXCL_RNG"
	| "EXCL_STNR"
	| "EXCL_STNR_PREF"
	| "ONLY_META"
	| "SLCT_ATTR"
	| "SLCT_INFO"
	| "SLCT_PERI"
	| "SLCT_PERI_REQ"
	| "SLCT_PROD"
	| "SLCT_RNG"
	| "SLCT_STNR"
	| "SLCT_STNR_PREF";
export type Format = "xml" | "json" | "jsonp";
export type ProductAtStop = {
	name: string;
	lineId: string;
	catCode: string;
};
export type StopLocation = {
	productAtStop: ProductAtStop[];
	id: string;
	extId: string;
	name: string;
	lon: number;
	lat: number;
	weight: number;
	products: number;
};
export type Link = {
	rel: string;
	href: string;
};
export type Icon = {
	res: string;
};
export type CoordLocation = {
	links?: Link[];
	icon: Icon;
	id: string;
	name: string;
	type: string;
	lon: number;
	lat: number;
	refinable?: boolean;
};
export type StopLocationOrCoordLocation =
	| {
			StopLocation: StopLocation;
	  }
	| {
			CoordLocation: CoordLocation;
	  };
export type LocationList = {
	stopLocationOrCoordLocation: StopLocationOrCoordLocation[];
	serverVersion: string;
	dialectVersion: string;
	requestId: string;
};
export type BadRequestError = {
	serverVersion: string;
	dialectVersion: string;
	errorCode: string;
	errorText: string;
	requestId: string;
};
export type UnauthorizedError = {
	fault: {
		faultstring: string;
		detail: {
			errorcode: string;
		};
	};
};
/**
 * Location search by name
 */
export function getLocationsByName(
	apikey: string,
	input: string,
	{
		coordLat,
		coordLong,
		filterMode,
		format,
		jsonpCallback,
		lang,
		maxNo,
		meta,
		products,
		r,
		refineId,
		requestId,
		sattributes,
		stations,
		type,
	}: {
		coordLat?: number;
		coordLong?: number;
		filterMode?: FilterMode;
		format?: Format;
		jsonpCallback?: string;
		lang?: string;
		maxNo?: number;
		meta?: string;
		products?: number;
		r?: number;
		refineId?: string;
		requestId?: string;
		sattributes?: string;
		stations?: string;
		type?: "A" | "ALL" | "AP" | "P" | "S" | "SA" | "SP";
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: LocationList;
		  }
		| {
				status: 400;
				data: BadRequestError;
		  }
		| {
				status: 401;
				data: UnauthorizedError;
		  }
	>(
		`/location.name${QS.query(
			QS.form({
				apikey,
				input,
				coordLat,
				coordLong,
				filterMode,
				format,
				jsonpCallback,
				lang,
				maxNo,
				meta,
				products,
				r,
				refineId,
				requestId,
				sattributes,
				stations,
				type,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Location search by coordinate
 */
export function getNearbyStops(
	apikey: string,
	originCoordLat: number,
	originCoordLong: number,
	{
		format,
		jsonpCallback,
		lang,
		maxNo,
		meta,
		products,
		r,
		requestId,
		sattributes,
		type,
	}: {
		format?: Format;
		jsonpCallback?: string;
		lang?: string;
		maxNo?: number;
		meta?: string;
		products?: number;
		r?: number;
		requestId?: string;
		sattributes?: string;
		type?: "S" | "P" | "SP";
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: LocationList;
		  }
		| {
				status: 400;
				data: BadRequestError;
		  }
		| {
				status: 401;
				data: UnauthorizedError;
		  }
	>(
		`/location.nearbystops${QS.query(
			QS.form({
				apikey,
				originCoordLat,
				originCoordLong,
				format,
				jsonpCallback,
				lang,
				maxNo,
				meta,
				products,
				r,
				requestId,
				sattributes,
				type,
			})
		)}`,
		{
			...opts,
		}
	);
}
