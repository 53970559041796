import {
	getLocationsByName,
	StopLocationOrCoordLocation,
} from "../utilities/location-api";

const getApiKey = (): string => {
	const apiKey = process.env.REACT_APP_SP_LOCATION_API_KEY;

	if (!apiKey) {
		throw new Error("Missing API key!");
	}

	return apiKey;
};

const getLocations = async (
	input: string
): Promise<StopLocationOrCoordLocation[]> => {
	if (input === "") {
		return [];
	}

	const response = await getLocationsByName(getApiKey(), input, {
		format: "json",
	});

	if (response.status !== 200) {
		console.error(response);
		throw new Error("Failed to fetch locations");
	}

	return response.data.stopLocationOrCoordLocation;
};

export default {
	getLocations,
};
