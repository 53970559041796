import React, { ReactElement, useState } from "react";
import {
	LayerGroup,
	LayersControl,
	MapContainer,
	TileLayer,
	ZoomControl,
	ScaleControl,
} from "react-leaflet";
import { latLngBounds, Map as LeafletMap } from "leaflet";
import { Drawer } from "antd";
import { getYear } from "date-fns";

import MapMarker from "../MapMarker";
import MarkerOperations from "../MarkerOperations";
import Search from "../Search";
import { SearchResultLocation } from "../../models/SearchResultLocation";
import { MarkerOptions } from "../../models/MarkerOptions";
import isMobileView from "../../utilities/responsive";

import styles from "./index.module.scss";

const DEFAULT_ZOOM = 14;
const COORDINATES_GROSSER_STERN: [number, number] = [52.51451, 13.35011];
const MAP_NORTH_WEST_BOUND: [number, number] = [52.99841, 12.12509];
const MAP_SOUTH_EAST_BOUND: [number, number] = [51.84717, 14.64748];

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TILE_SERVER_10 = `${BASE_URL}/data/10/{z}/{x}/{y}.png`;
const TILE_SERVER_20 = `${BASE_URL}/data/20/{z}/{x}/{y}.png`;
const TILE_SERVER_50 = `${BASE_URL}/data/50/{z}/{x}/{y}.png`;

const Map = (): ReactElement => {
	const [mapRef, setMapRef] = useState<LeafletMap>();
	const [selectedMarkerId, setSelectedMarkerId] = useState<string>("");
	const [mapMarkers, setMapMarkers] = useState<ReactElement[]>([]);

	const handleMarkerClick = (markerId: string) => {
		setSelectedMarkerId(markerId);
	};

	const handleMarkerOperations = (
		operation: MarkerOptions,
		id: string
	): void => {
		if (operation === "DELETE") {
			setMapMarkers(
				mapMarkers.filter((marker) => {
					return marker.props.id !== id;
				})
			);
			setSelectedMarkerId("");
		}
	};

	const handleSearch = (searchResultLocation: SearchResultLocation): void => {
		if (searchResultLocation) {
			setMapMarkers([
				...mapMarkers,
				<MapMarker
					key="map-marker"
					id={`marker-${searchResultLocation.geolocation.coordinates[0]}|${searchResultLocation.geolocation.coordinates[1]}`}
					position={[
						searchResultLocation.geolocation.coordinates[0],
						searchResultLocation.geolocation.coordinates[1],
					]}
					onClickCallback={handleMarkerClick}
				/>,
			]);
		}
	};

	const getAttribution = (): string => {
		const year = getYear(new Date());
		return `&copy; <a href="https://www.bvg.de/de/datenschutz">${year} Berliner Verkehrsbetriebe</a>`;
	};

	return (
		<>
			<div className={styles["map-container"]}>
				<MapContainer
					zoom={DEFAULT_ZOOM}
					center={COORDINATES_GROSSER_STERN}
					className={styles["leaflet-container"]}
					zoomControl={false}
					maxBounds={latLngBounds(MAP_NORTH_WEST_BOUND, MAP_SOUTH_EAST_BOUND)}
					whenCreated={setMapRef}
				>
					<ZoomControl position="bottomright" />
					<ScaleControl position="bottomleft" />
					<Search mapRef={mapRef} onSearchCallback={handleSearch} />
					<>{mapMarkers}</>
					<LayersControl position="topright">
						<LayersControl.BaseLayer checked name="BVG">
							<LayerGroup>
								<TileLayer
									attribution={getAttribution()}
									url={TILE_SERVER_10}
									maxZoom={16}
									minZoom={16}
								/>
								<TileLayer
									attribution={getAttribution()}
									url={TILE_SERVER_20}
									maxZoom={15}
									minZoom={14}
								/>
								<TileLayer
									attribution={getAttribution()}
									url={TILE_SERVER_50}
									maxZoom={13}
									minZoom={11}
								/>
							</LayerGroup>
						</LayersControl.BaseLayer>
						<LayersControl.BaseLayer name="OSM">
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
						</LayersControl.BaseLayer>
					</LayersControl>
				</MapContainer>
			</div>
			<Drawer
				visible={!!selectedMarkerId}
				placement={isMobileView() ? "bottom" : "right"}
				closable={false}
				onClose={() => setSelectedMarkerId("")}
			>
				<MarkerOperations
					id={selectedMarkerId}
					operationsCallback={handleMarkerOperations}
				/>
			</Drawer>
		</>
	);
};

export default Map;
