import React, { ReactElement } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import { Avatar, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { MarkerOptions } from "../../models/MarkerOptions";

import styles from "./index.module.scss";

type MarkerOperationsProps = {
	id: string;
	operationsCallback: (operation: MarkerOptions, id: string) => void;
};

const MarkerOperations = ({
	id,
	operationsCallback,
}: MarkerOperationsProps): ReactElement => {
	const { t } = useTranslation();
	return (
		<Row align="middle" justify="center" className={styles["menu"]}>
			<Col>
				<div
					className={styles["menu__item-wrapper"]}
					onClick={() => operationsCallback("DELETE", id)}
				>
					<Avatar
						className={styles["item"]}
						size={62}
						icon={
							<>
								<img
									className={styles["icon"]}
									src="./marker-icon.png"
									alt={t("map-operations.remove-marker")}
								/>
								<MinusCircleFilled className={styles["icon--overlay"]} />
							</>
						}
					/>
					<h3>{t("map-operations.remove-marker")}</h3>
				</div>
			</Col>
		</Row>
	);
};

export default MarkerOperations;
