import React, { ReactElement } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";

type MapMarkerProps = {
	id: string;
	position: L.LatLngExpression;
	onClickCallback: (markerId: string) => void;
};
const ICON_SIZE_WIDTH = 44;
const ICON_SIZE_HEIGHT = 44;

const { PUBLIC_URL } = process.env;

const MapMarker = ({
	id,
	position,
	onClickCallback,
}: MapMarkerProps): ReactElement => {
	const searchResultIcon = L.icon({
		iconUrl: `${PUBLIC_URL}/marker-icon.png`,
		iconSize: [ICON_SIZE_WIDTH, ICON_SIZE_HEIGHT],
		iconAnchor: [ICON_SIZE_WIDTH / 2, ICON_SIZE_HEIGHT],
	});
	return (
		<Marker
			position={position}
			icon={searchResultIcon}
			eventHandlers={{
				click: () => {
					onClickCallback(id);
				},
			}}
		></Marker>
	);
};

export default MapMarker;
