import { Resource } from "i18next";

export const de: Resource = {
	search: {
		placeholder: "Straße, Station oder Haltestelle eingeben",
		"not-possible": "Suchen ist gerade leider nicht möglich",
	},
	"map-operations": {
		"remove-marker": "Marker entfernen",
	},
	footer: {
		imprint: {
			title: "Impressum",
			url: "https://www.bvg.de/de/impressum",
		},
		"data-protection": {
			title: "Datenschutz",
			url: "https://www.bvg.de/de/datenschutz",
		},
		"general-terms": {
			title: "AGB",
			url: "https://www.bvg.de/de/agb",
		},
		"usage-policy": {
			title: "Nutzungsordnung",
			url: "https://www.bvg.de/de/service-und-kontakt/nutzungsordnung",
		},
		"passenger-rights": {
			title: "Fahrgastrechte",
			url: "https://www.bvg.de/de/service-und-kontakt/fahrgastrechte",
		},
		"customer-guarantee": {
			title: "Kundengarantie",
			url: "https://www.bvg.de/de/service-und-kontakt/kundengarantie",
		},
		"accessibility-declaration": {
			title: "Erklärung Barrierefreiheit",
			url: "https://www.bvg.de/de/erklaerung-barrierefreiheit",
		},
	},
};

export default de;
