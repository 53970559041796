import React, { ReactElement } from "react";
import { Col, Row } from "antd";
import isMobileView from "./../../utilities/responsive";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const Footer = (): ReactElement => {
	const isViewMobile = isMobileView();

	const { t } = useTranslation();
	return (
		<Row align={"middle"} className={styles["footer"]}>
			<Col span={isViewMobile ? 24 : 6} className={styles.copyright}>
				<div>© {new Date().getFullYear()} Berliner Verkehrsbetriebe</div>
			</Col>
			<Col span={isViewMobile ? 24 : 18} className={styles.links}>
				<a href={t("footer.imprint.url")}>{t("footer.imprint.title")}</a>
				<a href={t("footer.data-protection.url")}>
					{t("footer.data-protection.title")}
				</a>
				<a href={t("footer.general-terms.url")}>
					{t("footer.general-terms.title")}
				</a>
				<a href={t("footer.usage-policy.url")}>
					{t("footer.usage-policy.title")}
				</a>
				<a href={t("footer.passenger-rights.url")}>
					{t("footer.passenger-rights.title")}
				</a>
				<a href={t("footer.customer-guarantee.url")}>
					{t("footer.customer-guarantee.title")}
				</a>
				<a href={t("footer.accessibility-declaration.url")}>
					{t("footer.accessibility-declaration.title")}
				</a>
			</Col>
		</Row>
	);
};

export default Footer;
