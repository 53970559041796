import { Resource } from "i18next";

export const en: Resource = {
	search: {
		placeholder: "Search for street names, stations or stops",
		"not-possible": "Searching is unfortunately currently not possible",
	},
	"map-operations": {
		"remove-marker": "Remove marker",
	},
	footer: {
		imprint: {
			title: "Imprint",
			url: "https://www.bvg.de/en/imprint",
		},
		"data-protection": {
			title: "Data Protection",
			url: "https://www.bvg.de/en/privacy-policy",
		},
		"general-terms": {
			title: "General Terms and Conditions",
			url: "https://www.bvg.de/en/general-terms-and-conditions",
		},
		"usage-policy": {
			title: "Usage Policy",
			url: "https://www.bvg.de/en/connections/connection-search",
		},
		"passenger-rights": {
			title: "Passenger Rights",
			url: "https://www.bvg.de/en/service-and-support/passenger-rights",
		},
		"customer-guarantee": {
			title: "Customer Guarantee",
			url: "https://www.bvg.de/en/service-and-support/customer-guarantee",
		},
		"accessibility-declaration": {
			title: "Accessibility Declaration",
			url: "https://www.bvg.de/en/accessibility-declaration",
		},
	},
};

export default en;
